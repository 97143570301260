<template>
    <div class="feed-list">
        <template v-for="(item, index) in list" :key="index">
            <div v-if="(item.post.post_type == 2 || item.post.post_type == 3)" class="feed-item"
                @click="goInfo(item.post.post_id)">
                <!-- 图文 -->
                <template v-if="item.post.post_type == 2">
                    <div class="feed-img-box">
                        <img class="feed-img" :src="$imgUrlFilter(item.post.images[0].url, 160)" />
                    </div>
                </template>
                <!-- 视频 -->
                <template v-else-if="item.post.post_type == 3">
                    <div class="feed-img-box">
                        <img class="feed-img" :src="$imgUrlFilter(item.post.video.cover, 160)" />
                        <div class="shade">
                            <span class="icon-video2 icon"></span>
                            <span>视频</span>
                        </div>
                    </div>
                </template>
                <div class="feed-content">
                    <div class="content">{{ item.post.content }}</div>
                    <div class="time">{{ item.post.pub_time_str }}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import domain from '@/service/domain'
export default {
    props: ['list'],
    methods: {
        goInfo(id) {
            window.open(`${domain.zhongchou}/dongtai/details/${id}?id=${id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.feed-list {
    padding: 0 30px 40px;
    min-height: calc(100vh - 712px);

    .feed-item {
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #E8E8E8;

        cursor: pointer;

        .feed-img-box {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            position: relative;
            margin-right: 15px;
            overflow: hidden;

            .feed-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .shade {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba($color: #000000, $alpha: 0.5);
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;

                span.icon {
                    font-size: 20px;
                    margin-right: 4px;
                }
            }
        }

        .feed-content {
            width: 0;
            flex: 1;
            line-height: 1;

            .content {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 18px;
                margin-bottom: 12px;
            }

            .time {
                font-size: 16px;
                color: #7F7F7F;
            }
        }
    }
}
</style>
