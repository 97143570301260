<template>
    <div class="home-page-box">
        <div class="wrap">
            <div class="content">
                <div class="md_id">摩点ID: {{ user.user_id }}</div>
                <div class="user-icon">
                    <CompAvtor :img_src="user.icon" size="212" />
                </div>
                <div class="user-info">
                    <div class="name">{{ user.nickname }}</div>
                    <div class="col">
                        <div class="col-item">
                            <span class="nums">{{ (userInfo.follower_count || 0) }}</span>
                            <span>粉丝</span>
                        </div>
                        <div class="col-line"></div>
                        <div class="col-item">
                            <span class="nums">{{ (userInfo.follow_count || 0) }}</span>
                            <span>关注</span>
                        </div>
                        <!-- <template v-if="userInfo.all_like_count">
                            <div class="col-line"></div>
                            <div class="col-item">
                                <span class="nums">{{userInfo.all_like_count}}</span>
                                <span>被赞和喜欢</span>
                            </div>
                        </template> -->
                    </div>
                </div>
                <!-- 摩点认证 -->
                <div :class="['company', { 'clickable': user.vip_type == 2 }]" v-if="(user.auth_info_status == 200)"
                    @click="goUserBasicAuth(user.vip_type)">
                    <img v-if="(user.vip_type == 1)" src="https://p6.moimg.net/mdwxapp/auth_orange_icon.png" />
                    <img v-else-if="(user.vip_type == 2)" src="https://p6.moimg.net/mdwxapp/auth_blue_icon.png" />
                    <span>{{ user.auth_info }}</span>
                    <strong v-if="user.vip_type == 2" class="icon-arrow icon"></strong>
                </div>
                <div class="sign" v-if="user.user_content">签名：{{ user.user_content || '暂无' }}</div>
                <div class="relation-btns" v-if="!isSelf">
                    <div v-if="userDetail && cp_entrance" class="items items-message" @click="goRongIM">
                        <el-icon :size="18">
                            <ChatLineSquare />
                        </el-icon>
                        <span>私信</span>
                    </div>
                    <div v-if="userInfo.is_follow" class="items items-message items-unfollow" @click="followPeople(2)">
                        已关注
                    </div>
                    <el-button v-else type="primary" class="items-follow" @click="followPeople(1)">
                        <el-icon :size="18">
                            <Plus />
                        </el-icon>
                        <span>关注</span>
                    </el-button>
                </div>
            </div>
            <div class="container">
                <div class="tabs">
                    <template v-for="(item, index) in tabs" :key="index">
                        <div v-if="is_cp || (!is_cp && item.type !== 1)"
                            :class="['item', { 'active': item.type == checkedIndex }]" @click="change(item.type)">
                            {{ item.name }}
                        </div>
                    </template>
                </div>
                <div class="list-box" v-if="checkedIndex == 1" v-loading="pro_loading">
                    <template v-if="prolist.length">
                        <CompProList :key="'list' + checkedIndex" :list="prolist" />
                        <CompPagination :key="'page' + checkedIndex" :current-page="page" :page-size="page_size"
                            :total="total" @change="pageChange" />
                    </template>
                    <template v-else>
                        <CompError v-if="!pro_loading" :key="'error' + checkedIndex" class="cont-nothing-box"
                            message="暂无发起的众筹" />
                    </template>
                </div>
                <div class="list-box" v-else-if="checkedIndex == 2" v-loading="feed_loading">
                    <template v-if="feed_list.length">
                        <CompFeedList :list="feed_list" />
                        <CompPagination :key="'page' + checkedIndex" :pageSize="10" :total="feed_total"
                            @change="pageChange" />
                    </template>
                    <template v-else>
                        <CompError v-if="!feed_loading" :key="'error' + checkedIndex" message="暂无动态"
                            class="cont-nothing-box" icon-name="empty_post_icon" />
                    </template>
                </div>
                <div class="list-box" v-else-if="(checkedIndex == 3)" v-loading="support_loading">
                    <template v-if="support_list.length">
                        <CompProList :key="'list' + checkedIndex" :list="support_list" :cardImgKey="'logo_1x1'" />
                        <CompPagination :key="'page' + checkedIndex" :current-page="support_page" :page-size="page_size"
                            :total="support_total" @change="pageChange" />
                    </template>
                    <template v-else>
                        <CompError v-if="!support_loading" :key="'error' + checkedIndex" class="cont-nothing-box"
                            message="暂无支持的项目" />
                    </template>
                </div>
                <div class="list-box" v-else-if="(checkedIndex == 4)" v-loading="like_loading">
                    <template v-if="like_list.length">
                        <CompProList :key="'list' + checkedIndex" :list="like_list" />
                        <CompPagination :key="'page' + checkedIndex" :current-page="like_page" :page-size="page_size"
                            :total="like_total" @change="pageChange" />
                    </template>
                    <template v-else>
                        <CompError v-if="!like_loading" :key="'error' + checkedIndex" class="cont-nothing-box"
                            message="暂无看好的项目" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import domain from '@/service/domain'
import { mapState } from "vuex"
import { ElMessageBox } from "element-plus";

import { GetUserInfo, GetBuildProductList, backProductList, myFavorsProductList, getCommFeedU, follow } from "@/api/index"

import CompProList from "@@/user/homepage/proList.vue"
import CompFeedList from "@@/user/homepage/feedList.vue"
import CompPagination from "@@/common/pagination.vue"
import CompError from "@@/common/error.vue"
import CompAvtor from "@@/compose/avtor.vue"
export default {
    components: {
        CompProList,
        CompFeedList,
        CompPagination,
        CompError,
        CompAvtor
    },
    computed: {
        ...mapState({
            userId: state => state.user.userId,
            userDetail: state => state.user.userDetail,
            userCpTag: state => state.user.userCpTag,
        }),
        isSelf() {
            return this.userId == this.to_user_id
        },
        cp_entrance() { // 是否展示私信
            return(
                this.userCpTag.user_cp_tag_flag || 
                this.userCpTag.common_user_cp_flag || 
                this.userCpTag.union_user_cp_flag || 
                this.userCpTag.customer_user_cp_tag_flag
            )
        }
    },
    data() {
        return {
            is_cp: false, // 是否是cp
            tabs: [{
                type: 1,
                name: '发起的众筹'
            }, {
                type: 2,
                name: 'TA的动态'
            }, {
                type: 3,
                name: '支持的项目'
            }, {
                type: 4,
                name: '看好的项目'
            }],
            checkedIndex: 1,
            to_user_id: '',
            userInfo: {},
            user: {},
            prolist: [],
            page: 1,
            page_size: 20,
            total: 0,
            pro_loading: true,

            support_list: [],
            support_page: 1,
            support_total: 0,
            support_loading: true,

            like_list: [],
            like_page: 1,
            like_total: 0,
            like_loading: true,

            feed_list: [],
            feed_page: 1,
            feed_total: 0,
            request_id: '',
            feed_loading: true
        }
    },
    async created() {
        const to_user_id = this.$route.params.id ? this.$route.params.id.replace(".html", "") : "";
        this.to_user_id = to_user_id
        this.getUserInfo()
    },
    methods: {
        async getUserInfo() {
            const res = await GetUserInfo({
                user_id: this.to_user_id
            })
            if (res.status == 0) {
                this.userInfo = res.data
                this.user = res.data.user
                this.is_cp = res.data.is_cp
            }
            if (this.is_cp) {
                this.getProList()
            } else {
                this.checkedIndex = 2
                this.getFeed()
            }
        },
        change(index) {
            this.checkedIndex = index
            switch (index) {
                case 1:
                    this.getProList();
                    break;
                case 2:
                    this.request_id = ''
                    this.getFeed()
                    break;
                case 3:
                    this.getSupportList()
                    break;
                case 4:
                    this.getLikeList();
                    break;
            }
        },
        async getProList() {
            this.pro_loading = true
            const { status, data } = await GetBuildProductList({
                to_user_id: this.to_user_id,
                page: this.page,
                page_size: this.page_size
            })
            if (status == 0) {
                this.prolist = data.list
                this.total = data.total
            }
            this.pro_loading = false
        },
        async getSupportList() {
            this.support_loading = true
            const { status, data } = await backProductList({
                to_user_id: this.to_user_id,
                page: this.support_page,
                page_size: this.page_size,
            })
            if (status == 0) {
                this.support_list = data.list
                this.support_total = Number(data.total)
            }
            this.support_loading = false
        },
        async getLikeList() {
            this.like_loading = true
            const { status, data } = await myFavorsProductList({
                to_user_id: this.to_user_id,
                page: this.like_page,
                page_size: this.page_size,
            })
            if (status == 0) {
                this.like_list = data.list
                this.like_total = Number(data.total)
            }
            this.like_loading = false
        },
        async getFeed() {
            this.feed_loading = true
            const { status, data } = await getCommFeedU({
                user_id: this.to_user_id,
                page: this.feed_page,
                page_size: this.page_size,
                request_id: this.request_id
            })
            if (status == 0) {
                this.feed_list = data.items
                this.request_id = data.request_id
                this.feed_total = data.total
            }
            this.feed_loading = false
        },
        pageChange(val) {
            switch (this.checkedIndex) {
                case 1:
                    this.page = val
                    this.getProList();
                    break;
                case 2:
                    this.feed_page = val
                    if (val == 1) {
                        this.request_id = ''
                    }
                    this.getFeed()
                    break;
                case 3:
                    this.support_page = val
                    this.getSupportList()
                    break;
                case 4:
                    this.like_page = val
                    this.getLikeList();
                    break;
            }
        },
        async followPeople(type) {
            if (!this.userId) {
                return ElMessageBox.confirm(
                    '请先登录',
                    '提示',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消'
                    }
                )
                    .then(() => {
                        location.href = domain.account
                    })
            }
            const { status } = await follow({
                to_user_id: this.to_user_id,
                type: type // 1-关注 2-取消
            });
            if (status == 0) {
                this.userInfo.is_follow = type == 1 ? true : false;
                this.$message.success(type == 1 ? '关注成功' : '取消成功')
            }
        },
        // 私信
        goRongIM() {
            window.open(`${domain.account}/RongIM?user_id=${this.to_user_id}`)
        },
        // 去企业认证资料
        goUserBasicAuth(type) {
            if (type == 2) {
                window.open(`${domain.me}/u/user_basic_auth?uid=${this.to_user_id}`)
                // let newTab = window.open('about:blank');
                // newTab.location.href = domain.me + '/u/user_auth_view?uid='+this.to_user_id+'&from=cp';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.home-page-box {
    width: 100%;
    background: #fff url('~@assets/images/user/homepage_bg.png') no-repeat top center;
    background-size: 100% auto;

    .wrap {
        width: 1080px;
        margin: 0 auto;

        .content {
            width: 1080px;
            background: url('~@assets/images/user/homepage_user_bg.png') no-repeat top center;
            background-size: cover;
            border-radius: 8px;
            padding: 74px 20px 20px;
            position: relative;
            margin-bottom: 20px;

            .md_id {
                font-size: 14px;
                text-align: right;
                color: #82808E;
                position: absolute;
                right: 18px;
                top: 18px;
                line-height: 1;
            }

            .user-icon {
                width: 106px;
                height: 106px;
                border-radius: 50%;
                position: absolute;
                top: 41px;
                left: 56px;
                overflow: hidden;
                background-color: #E8E8E8;
            }

            .user-info {
                padding-left: 186px;
                height: 92px;

                .name {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 1;
                    height: 24px;
                }

                .col {
                    height: 48px;
                    display: flex;
                    margin-top: 20px;
                    align-items: center;

                    .col-item {
                        display: flex;
                        flex-direction: column;
                        color: #7F7F7F;

                        .nums {
                            font-weight: bold;
                            font-size: 20px;
                            color: #1A1A1A;
                        }
                    }

                    .col-line {
                        width: 1px;
                        height: 20px;
                        background: #D8D8D8;
                        margin: 0 30px;
                    }
                }
            }

            .company {
                font-size: 18px;
                margin-top: 30px;
                display: flex;
                align-items: center;

                &.clickable {
                    cursor: pointer;
                }

                img {
                    width: 22px;
                    height: 22px;
                    margin-right: 3px;
                }

                .icon {
                    font-weight: bold;
                    font-size: 16px;
                }
            }

            .sign {
                color: #7F7F7F;
                font-size: 18px;
                line-height: 28px;
                margin-top: 20px;
            }

            .relation-btns {
                display: flex;
                font-size: 16px;
                position: absolute;
                right: 20px;
                top: 68px;

                .items {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    width: 92px;
                    height: 40px;
                    border-radius: 4px;

                    &-message {
                        color: #7F7F7F;
                        border: 1px solid #B1B1B1;
                        &:hover {
                            color: #1A1A1A;
                            border-color: #1A1A1A;
                        }
                    }

                    &-unfollow {
                        margin-left: 10px;
                    }

                    &-follow {
                        margin-left: 10px;
                    }

                    span {
                        margin-left: 6px;
                    }
                }
            }
        }

        .container {
            padding-bottom: 44px;
            background: #FFFFFF;
            border-radius: 8px 8px 0px 0px;

            .tabs {
                padding: 0 30px;
                line-height: 80px;
                font-size: 16px;
                border-bottom: 1px solid #E8E8E8;
                display: flex;

                .item {
                    margin-right: 30px;
                    position: relative;
                    cursor: pointer;

                    &.active::before {
                        content: '';
                        display: block;
                        width: 24px;
                        height: 6px;
                        background: #1A1A1A;
                        border-radius: 2px;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%);
                    }
                }
            }

            .list-box {
                min-height: 430px;
                position: relative;

                .cont-nothing-box {
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
</style>
