<template>
    <div class="pro-list">
        <template v-for="(item, index) in list" :key="index">
            <div :class="['pro-item', { 'pro-item-last': (index + 1) % 4 == 0 }]">
                <CompProCard :item="item" :cardImgKey="cardImgKey" />
            </div>
        </template>
    </div>
</template>

<script>
import CompProCard from "@@/user/proCard.vue"
export default {
    components: {
        CompProCard
    },
    props: ['list', 'cardImgKey']
}
</script>

<style lang="scss" scoped>
.pro-list {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: calc(100vh - 712px);

    .pro-item {
        width: calc((100% - 60px) / 4);
        margin-bottom: 20px;
        margin-right: 20px;
        border: 1px solid #E8E8E8;
        border-radius: 8px;

        &-last {
            margin-right: 0;
        }

        .content {
            padding: 10px 15px;

            .title {
                line-height: 24px;
                height: 48px;
                font-weight: bold;
                font-size: 16px;
            }

            .company {
                padding: 12px 0 12px;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid #E8E8E8;
                    margin-right: 8px;
                }

                &-name {
                    flex: 1;
                    width: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}</style>
